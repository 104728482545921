import React from "react";
import ProjectCard from "./ProjectCard"; // Assuming ProjectCard.js is in the same directory

const ProjectCardContainer = () => {
  // Sample project data
  const projects = [
    {
      title: "TrekMate App",
        githubLink: "https://github.com/dhruvx19/ChronoSync_app",
        youtubeLink: "https://youtube.com/project1",
        liveLink: "https://example.com/project1",
      },
  
    {
        title: "ChronoSync App",
        githubLink: "https://github.com/dhruvx19/TrekMate_app",
        youtubeLink: "https://youtube.com/project1",
        liveLink: "https://example.com/project1",
      },
      {
        title: "RuralRoots App",
      githubLink: "https://github.com/dhruvx19/RuralRoots_app",
      youtubeLink: "https://youtube.com/project1",
      liveLink: "https://example.com/project1",
      }
      // {
      //   title: "Legalease",
      //   description: "Hackathon Project, Introducing LegaEase, your all-in-one legal support platform. With our AI-powered chatbot, document generation becomes effortless. Expert consultations are readily available, and our secure document storage ensures your files are always safe. Experience convenience and peace of mind with LegaEase for all your legal needs.",
      //   imageUrl: "https://imgur.com/a/8zls1Q3",
      //   githubLink: "https://github.com/dhruvx19/Legalease",
      //   liveDemoLink: "https://legalease-ebon.vercel.app",

        
      // },
    // Add more projects as needed
  ];

  return (
    <div className="flex flex-wrap justify-center gap-6">
      {projects.map((project, index) => (
        <ProjectCard key={index} {...project} />
      ))}
    </div>
  );
};

export default ProjectCardContainer;
