import React, { useContext } from "react";
import { serviceData } from "../constants";
import { ThemeContext } from "../themeProvider";
import { motion } from "framer-motion";

const Development = () => {
  const theme = useContext(ThemeContext);
  return (
    <div
      className={
        theme.state.darkMode ? "pb-20 bg-fixed bg-gray-100" : "pb-20 bg-black"
      }
    >
      <div
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 px-4 pt-20"
        id="Development"
      >
        <h2
          className={
            theme.state.darkMode
              ? "text-5xl font-bold px-4 md:px-0 text-center"
              : "text-5xl font-bold px-4 md:px-0 text-center text-white"
          }
        >
          Development
        </h2>
        <div className="mt-8 flex flex-wrap justify-center items-stretch">
          {/* Changed items-center to items-stretch */}
          {serviceData.map((el) => (
            <motion.div
              key={el.name}
              initial="hidden"
              whileInView={"visible"}
              variants={{
                visible: { opacity: 1, scale: 1 },
                hidden: { opacity: 0, scale: 0 },
              }}
              className={
                theme.state.darkMode
                  ? "md:w-96 p-4 bg-white rounded-lg flex flex-col justify-between mt-8 mx-4"
                  : "md:w-96 p-4 bg-gray-100 rounded-lg flex flex-col justify-between mt-8 mx-4"
              }
            >
              <div className="flex-grow">
                <img src={el.img} alt="" className="w-24 h-24" />
                <h4 className="text-xl font-bold mt-4">{el.name}</h4>
                <p className="text-lg mt-2 text-justify">{el.desc}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Development; // Corrected export statement
