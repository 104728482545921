import React from "react";
import { motion } from "framer-motion";
import image1 from "./image1.jpg"; // Import the image file
import image2 from "./image2.jpg"; // Import the image file
import image3 from "./image3.jpg"; // Import the image file

const ProjectCard = ({ title, description, githubLink, youtubeLink, liveDemoLink }) => {
  // Store the imported images in an array
  const images = [image1, image2, image3];

  return (
    <motion.div
      initial={"hidden"}
      whileInView={"visible"}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="max-w-xl bg-white rounded-lg border border-gray-200 shadow-lg dark:bg-gray-800 dark:border-gray-700 my-8"
      style={{ width: '400px', height: '500px' }} // Adjust the width and height here
    >
      <img
        className="rounded-t-lg w-full h-60 object-cover"
        src={images[Math.floor(Math.random() * images.length)]} // Use the imported images array
        alt={title}
      />
      <div className="p-5">
        <a href={githubLink} target="_blank" rel="noopener noreferrer">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {title}
          </h5>
        </a>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
          {description}
        </p>
        <div className="flex space-x-4">
          <a href={githubLink} target="_blank" rel="noopener noreferrer" className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-blue-500 rounded-lg hover:bg-blue-200 focus:ring-4 focus:outline-none focus:ring-blue-300">
            GitHub
          </a>
          {youtubeLink && (
            <a
              href={`https://${youtubeLink}`}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-red-500 rounded-lg hover:bg-red-200 focus:ring-4 focus:outline-none focus:ring-red-300"
            >
              YouTube
            </a>
          )}

          {liveDemoLink && (
            <a href={liveDemoLink} target="_blank" rel="noopener noreferrer" className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded-lg hover:bg-green-200 focus:ring-4 focus:outline-none focus:ring-green-300">
              Live Demo
            </a>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default ProjectCard;
